import React, { useCallback, useState } from 'react'
import cx from 'clsx'

import Select, { useSelectHelpers } from '@components/Form/ReactSelect'

import { useGetMyAccount } from '@src/api/queries/Account'
import { useGetCryptoCurrencies } from '@src/api/queries/CryptoCurrencies'
import { currencyOptions, priorityCurrencies } from '@src/constants'

import '@tabler/core/dist/css/tabler-flags.min.css'
import { ActionMeta, OnChangeValue, SingleValue } from 'react-select'

export interface CurrencyOption extends Option<string> {
  flag?: string
}

interface CurrencySelectorProps {
  onChange: (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => void
  isCrypto?: boolean
  value?: string
  name?: string
  label?: string
  isClearable?: boolean
  isDisabled?: boolean
  menuPortalTarget?: HTMLElement | null
  required?: boolean
  placeholder?: string
  className?: string
  hideDefaultLabel?: boolean
  errorMessage?: string
  classes?: {
    control?: string
    label?: string
    container?: string
    errorMessage?: string
  }
  optionsFilter?: (option: CurrencyOption) => boolean
}

const CurrencySelector: React.FC<CurrencySelectorProps> = ({
  onChange,
  name = 'currency',
  label,
  isClearable = false,
  isDisabled = false,
  menuPortalTarget = null,
  required = false,
  placeholder = 'Search currency...',
  className,
  value,
  hideDefaultLabel = false,
  errorMessage,
  classes,
  optionsFilter,
  isCrypto = false,
}) => {
  const { data: user } = useGetMyAccount()
  const { data: cryptoCurrencies } = useGetCryptoCurrencies(isCrypto)

  const [isFocused, setIsFocused] = useState(false)

  const currencyNames = new Intl.DisplayNames(['en'], { type: 'currency' })
  const defaultCurrency = user?.team?.default_currency

  const getCurrencies = () => {
    if (isCrypto) {
      return cryptoCurrencies!.map(({ symbol }) => ({
        code: symbol,
        name: symbol,
        flag: undefined,
      }))
    } else {
      return currencyOptions.map((currency) => ({
        code: currency.value,
        name: currencyNames.of(currency.value),
        flag: currency.value.slice(0, 2).toLowerCase(),
      }))
    }
  }

  const currencies = getCurrencies()

  const formatOptionLabel = (option: CurrencyOption) => {
    const isDefaultCurrency = option.value === defaultCurrency

    if (isCrypto) {
      return (
        <div className="flex items-center gap-3 w-full">
          <div className="flex gap-1 items-center truncate justify-between w-full">
            <span>{option.label}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className="flex items-center gap-3 w-full">
          <div className="relative min-w-[22px] h-3.5">
            <div
              className={cx(
                `!absolute !bg-[length:22px_14px] top-0 left-0 !w-[22px] !h-3.5 flag flag-xs flag-country-${option.flag}`,
                option.value === 'EUR' && '!bg-[length:23px_15px] !bg-[0_-1px]',
              )}
            />
            <div className="absolute top-0 left-0 w-full -z-[1] h-full border bg-neutral-50 rounded-sm" />
          </div>
          <div className="flex gap-1 items-center truncate justify-between w-full">
            <div className="flex gap-1 items-center truncate">
              <span>{`${option.value}${' '}`}</span>
              <span className="text-neutral-500 truncate">{`• ${option.label}`}</span>
            </div>
            {!hideDefaultLabel && isDefaultCurrency && (
              <span className="inline-block truncate leading-[10px] text-xs ml-2 bg-green-200 text-green-800 px-2 py-1 rounded-base">
                Team currency
              </span>
            )}
          </div>
        </div>
      )
    }
  }

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  const handleChange = useCallback(
    (newValue: SingleValue<Option<string>>, meta: ActionMeta<Option<string>>) => {
      onChange(newValue, meta)
    },
    [onChange, name],
  )

  const sortOptions = () => {
    if (isCrypto) {
      let options = currencies.map((currency) => ({
        value: currency.code,
        label: currency.name,
      })) as CurrencyOption[]

      if (optionsFilter) {
        options = options.filter(optionsFilter)
      }

      return options.sort((a, b) => a.label.localeCompare(b.label))
    } else {
      const defaultCurrencyObj = currencies.find((currency) => currency.code === defaultCurrency)
      const filteredPriorityCurrencies = priorityCurrencies.filter((code) => code !== defaultCurrency)
      const priorityCurrenciesList = filteredPriorityCurrencies
        .map((code) => currencies.find((currency) => currency.code === code))
        .filter((currency) => currency)

      const otherCurrencies = currencies.filter(
        (currency) => !priorityCurrencies.includes(currency.code) && currency.code !== defaultCurrency,
      )

      const sortedOtherCurrencies = otherCurrencies.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name)
        }
        return 0
      })
      let options = [defaultCurrencyObj, ...priorityCurrenciesList, ...sortedOtherCurrencies]
        .filter(Boolean)
        .map((currency) => {
          if (!currency) return null
          return {
            value: currency.code,
            label: currency.name,
            flag: currency.flag,
          }
        }) as CurrencyOption[]

      if (optionsFilter) {
        options = options.filter(optionsFilter)
      }
      return options
    }
  }

  const formattedOptions = sortOptions()

  const { optionValue } = useSelectHelpers(formattedOptions as Option<string>[], value)

  return (
    <Select
      className={cx(className)}
      options={formattedOptions as CurrencyOption[]}
      formatOptionLabel={formatOptionLabel}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMenuClose={() => setIsFocused(false)}
      name={name}
      onChange={handleChange}
      value={isFocused ? null : optionValue}
      isDisabled={isDisabled}
      isClearable={isClearable}
      menuPortalTarget={menuPortalTarget}
      required={required}
      placeholder={placeholder}
      label={label}
      errorMessage={errorMessage}
      classes={{
        label: cx(classes?.label),
        control: cx(classes?.control),
        container: cx(classes?.container),
        errorMessage: cx(classes?.errorMessage),
      }}
    />
  )
}

export default CurrencySelector
